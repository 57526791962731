import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllGrades = (headers) => {
  return ApiService.get("grades", "", headers);
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getGrade = (id) => {
  return ApiService.get("grades", id);
};

/**
 * POST request to create a new grade
 * @param payload
 * @returns {*}
 */
export const createGrade = (payload) => {
  return ApiService.post("grades", payload);
};

/**
 * PUT request to update a specific grade
 * @param id
 * @param payload
 * @returns {*}
 */
export const updateGrade = (id, payload) => {
  return ApiService.update("grades", id, payload);
};

/**
 * DELETE request to delete the specified grade
 * @param id
 * @returns {*}
 */
export const deleteGrade = (id) => {
  return ApiService.delete(`grades/${id}`);
};

/**
 * GET request to change status of the specified grade
 * @param id
 * @returns {*}
 */
export const changeStatusGrade = (id) => {
  return ApiService.get("grades/status", id);
};

export default {
  getAllGrades,
  getGrade,
  createGrade,
  updateGrade,
  deleteGrade,
  changeStatusGrade,
};
