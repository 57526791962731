import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllTechnicalGroups = () => {
  return ApiService.get("technical_groups");
};

/**
 * GET request to fetch a technical groups of one grade
 * @returns {*}
 */
export const getTechnicalGroupsByGrade = (id) => {
  return ApiService.get("technical_groups/grade", id);
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getTechnicalSections = (groupable_id) => {
  return ApiService.get(`technical_groups/${groupable_id}/sections`);
};

/**
 * POST request to save technical groups of grades
 * @param payload
 * @returns {*}
 */
export const saveTechnicalGroup = (payload) => {
  return ApiService.post("technical_groups", payload);
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getTeacherGroupOfGrade = (id) => {
  return ApiService.get("technical_groups/teachers", id);
};

/**
 * PUT request to update a specific academic_level
 * @param id
 * @param payload
 * @returns {*}
 */
export const updateOrCreateTeacherGroup = (id, payload) => {
  return ApiService.update("technical_groups/teachers", id, payload);
};

export default {
  getAllTechnicalGroups,
  getTechnicalGroupsByGrade,
  saveTechnicalGroup,
  getTeacherGroupOfGrade,
  updateOrCreateTeacherGroup,
  getTechnicalSections
};
