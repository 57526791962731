import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch a academic load of one subject
 * @returns {*}
 */
export const getAcademicLoadsBySubject = (id) => {
  return ApiService.get("academic_loads/subject", id);
};

/**
 * POST request to create a new academic_laad
 * @param payload
 * @returns {*}
 */
export const createAcademicLoad = (payload) => {
  return ApiService.post("academic_loads", payload);
};

/**
 * PUT request to update a specific academic_laad
 * @param id
 * @param payload
 * @returns {*}
 */
export const updateAcademicLoad = (id, payload) => {
  return ApiService.update("academic_loads", id, payload);
};

export default {
  getAcademicLoadsBySubject,
  createAcademicLoad,
  updateAcademicLoad,
};
