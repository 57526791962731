import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllSubjectEvaluationScales = () => {
  return ApiService.get("subject-evaluation-scales");
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getSubjectEvaluationScale = (id) => {
  return ApiService.get("subject-evaluation-scales", id);
};

/**
 * POST request to create a new subject evaluation scale
 * @param payload
 * @returns {*}
 */
export const createSubjectEvaluationScale = (payload) => {
  return ApiService.post("subject-evaluation-scales", payload);
};

/**
 * PUT request to update a specific subject evaluation scale
 * @param id
 * @param payload
 * @returns {*}
 */
export const updateSubjectEvaluationScale = (id, payload) => {
  return ApiService.update("subject-evaluation-scales", id, payload);
};

/**
 * DELETE request to delete the specified subject evaluation scale
 * @param id
 * @returns {*}
 */
export const deleteSubjectEvaluationScale = (id) => {
  return ApiService.delete(`subject-evaluation-scales/${id}`);
};

/**
 * GET request to change status of the specified subject evaluation scale
 * @param id
 * @returns {*}
 */
export const changeStatusSubjectEvaluationScale = (id) => {
  return ApiService.get("subject-evaluation-scales/status", id);
};

export default {
  getAllSubjectEvaluationScales,
  getSubjectEvaluationScale,
  createSubjectEvaluationScale,
  updateSubjectEvaluationScale,
  deleteSubjectEvaluationScale,
  changeStatusSubjectEvaluationScale,
};
