import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllAcademicGroups = () => {
  return ApiService.get("academic_groups");
};

/**
 * GET request to fetch a academic groups of one grade
 * @returns {*}
 */
export const getAcademicGroupsByGrade = (id) => {
  return ApiService.get("academic_groups/grade", id);
};

/**
 * GET request to fetch a academic groups by grade_speciality_group_id
 * @returns {*}
 */
export const getAcademicGroupsByTechnicalGroup = (
  grade_speciality_group_id
) => {
  return ApiService.get(
    `technical_groups/${grade_speciality_group_id}/academic_groups`
  );
};

/**
 * GET request to fetch a sections of academic groups
 * @returns {*}
 */
export const getSectionsOfAcademicGroups = ({ grade_id, speciality_id }) => {
  let slugs = [];
  slugs.push(grade_id);
  slugs.push(speciality_id);
  return ApiService.get("academic_groups/grade/sections", slugs);
};

/**
 * POST request to save academic groups of grades
 * @param payload
 * @returns {*}
 */
export const saveGradeAcademicGroups = (payload) => {
  return ApiService.post("academic_groups", payload);
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getTeacherGroupOfGrade = (id) => {
  return ApiService.get("academic_groups/teachers", id);
};

/**
 * PUT request to update a specific academic_level
 * @param id
 * @param payload
 * @returns {*}
 */
export const updateOrCreateTeacherGroup = (id, payload) => {
  return ApiService.update("academic_groups/teachers", id, payload);
};

export default {
  getAllAcademicGroups,
  getAcademicGroupsByGrade,
  saveGradeAcademicGroups,
  getTeacherGroupOfGrade,
  updateOrCreateTeacherGroup,
  getSectionsOfAcademicGroups,
  getAcademicGroupsByTechnicalGroup,
};
