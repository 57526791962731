<template>
  <div>
    <!-- begin::Card -->
    <div class="card card-custom gutter-b">
      <div class="card-header flex-nowrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">
            Gestión de asignaturas
            <span class="d-block text-muted pt-2 font-size-sm"
              >Aqui se podra visualizar, crear, actualizar, eliminar y cambiar
              el estado de las asignaturas.</span
            >
          </h3>
        </div>
        <div class="card-toolbar">
          <!--begin::Button New Record-->
          <v-btn
            elevation="0"
            color="primary"
            @click="showMdlCreate"
            v-if="existInArray('Agregar', currentPageActions)"
          >
            <span class="svg-icon svg-icon-md svg-icon-white mr-1">
              <inline-svg src="media/svg/icons/Home/Book-open.svg"></inline-svg>
            </span>
            Crear asignatura
          </v-btn>
          <!--end::Button New Record-->
        </div>
      </div>
      <div class="card-body">
        <!-- begin::Search Form -->
        <div class="mb-5">
          <div class="row align-items-center">
            <div class="col-lg-12 col-xl-12">
              <div class="row align-items-center">
                <div class="col-md-3 my-2 my-md-0">
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    prepend-icon=""
                    prepend-inner-icon="mdi-magnify"
                    placeholder="Buscar..."
                    v-model="subjects.search"
                  ></v-text-field>
                </div>
                <div class="col-md-3 my-2 my-md-0">
                  <div class="d-flex align-items-center">
                    <v-select
                      :label="
                        subjectEvaluationScales.isLoading
                          ? 'Cargando tipos de asignaturas'
                          : 'Tipo de asignatura'
                      "
                      :loading="subjectEvaluationScales.isLoading"
                      :disabled="!subjectEvaluationScales.data.length"
                      outlined
                      dense
                      hide-details
                      clearable
                      multiple
                      :items="subjectEvaluationScales.data"
                      item-text="name"
                      item-value="id"
                      v-model="subjects.filters.subject_evaluation_scale_id"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip color="primary" small v-if="index === 0">
                          <span>{{
                            `${item.subject_type}, ${item.subject_evaluation_methodology}`
                          }}</span>
                        </v-chip>
                        <span
                          v-if="index === 1"
                          class="grey--text text-caption"
                        >
                          (+{{
                            subjects.filters.subject_evaluation_scale_id
                              .length - 1
                          }}
                          seleccionados)
                        </span>
                      </template>
                      <template v-slot:item="data">
                        <span>
                          {{
                            `${data.item.subject_type}, ${data.item.subject_evaluation_methodology}`
                          }}
                        </span>
                      </template>
                    </v-select>
                  </div>
                </div>
                <div class="col-md-3 my-2 my-md-0">
                  <div class="d-flex align-items-center">
                    <v-select
                      :label="grades.isLoading ? 'Cargando grados' : 'Grado'"
                      :loading="grades.isLoading"
                      :disabled="!grades.data.length"
                      outlined
                      dense
                      hide-details
                      clearable
                      multiple
                      :items="grades.data"
                      item-text="name"
                      item-value="id"
                      v-model="subjects.filters.grade_id"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip color="primary" small v-if="index === 0">
                          <span>{{ item.name }}</span>
                        </v-chip>
                        <span
                          v-if="index === 1"
                          class="grey--text text-caption"
                        >
                          (+{{ subjects.filters.grade_id.length - 1 }}
                          seleccionados)
                        </span>
                      </template>
                    </v-select>
                  </div>
                </div>
                <div class="col-md-3 my-2 my-md-0">
                  <div class="d-flex align-items-center">
                    <v-select
                      :label="
                        specialities.isLoading
                          ? 'Cargando especialidades'
                          : 'Especialidad'
                      "
                      :loading="specialities.isLoading"
                      :disabled="!specialities.data.length"
                      outlined
                      dense
                      hide-details
                      clearable
                      multiple
                      :items="specialities.data"
                      item-text="name"
                      item-value="id"
                      v-model="subjects.filters.speciality_id"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip color="primary" small v-if="index === 0">
                          <span>{{ item.name }}</span>
                        </v-chip>
                        <span
                          v-if="index === 1"
                          class="grey--text text-caption"
                        >
                          (+{{ subjects.filters.speciality_id.length - 1 }}
                          seleccionados)
                        </span>
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end::Search Form -->
        <!--begin: Datatable-->
        <v-card>
          <v-data-table
            :headers="subjects.headers"
            :items="filteredData"
            :search="subjects.search"
            :items-per-page="10"
            :loading="subjects.isLoading"
          >
            <!-- begin: Actions -->
            <template v-slot:[`item.actions`]="{ item }">
              <template v-if="existInArray('Editar', currentPageActions)">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      @click="
                        selectSubject(item);
                        showMdlUpdate();
                      "
                      color="amber"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
              </template>
              <template v-if="existInArray('Eliminar', currentPageActions)">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-5"
                      @click="
                        selectSubject(item);
                        showMdlDelete();
                      "
                      color="red"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </template>

              <template
                v-if="existInArray('Gestionar etapas', currentPageActions)"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-5"
                      @click="
                        selectSubject(item);
                        showMdlAcademicLoad();
                      "
                      color="deep-purple"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-book-clock
                    </v-icon>
                  </template>
                  <span>Gestionar etapas</span>
                </v-tooltip>
              </template>
              <template
                v-if="existInArray('Gestionar docentes', currentPageActions)"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-5"
                      @click="
                        selectSubject(item);
                        showMdlSubjectTeachers();
                      "
                      color="light-green"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-human-male-board
                    </v-icon>
                  </template>
                  <span>Gestionar docentes</span>
                </v-tooltip>
              </template>
              <template
                v-if="
                  existInArray('Asignar docentes a grupos', currentPageActions)
                "
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-5"
                      @click="
                        selectSubject(item);
                        showMdlSubjectAcademicGroups();
                      "
                      color="pink"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-human-male-board-poll
                    </v-icon>
                  </template>
                  <span>Asignar docentes a grupos</span>
                </v-tooltip>
              </template>
            </template>
            <!-- end: Acions -->
          </v-data-table>
        </v-card>
        <!-- end: Datatable-->
      </div>
    </div>
    <!-- end::Card -->

    <!-- begin::Modals -->
    <CreateSubjectModal
      ref="mdlCreate"
      :subjectEvaluationScales="subjectEvaluationScales.data"
      :grades="grades.data"
      :specialities="specialities.data"
      @reload-data="loadSubjects"
    ></CreateSubjectModal>

    <UpdateSubjectModal
      ref="mdlUpdate"
      @reload-data="loadSubjects"
      @clean-slection="unselectSubject"
      :subject="selectedSubject"
      :subjectEvaluationScales="subjectEvaluationScales.data"
      :grades="grades.data"
      :specialities="specialities.data"
    ></UpdateSubjectModal>

    <DeleteBaseModal ref="mdlDelete" @delete="deleteSubject">
      <template v-slot:title>¿Desea eliminar esta asignatura?</template>
      <template v-slot:subject>
        la asignatura
        <span class="font-weight-bolder">{{ selectedSubject.name }}</span>
      </template>
    </DeleteBaseModal>

    <ChangeStatusBaseModal
      ref="mdlChangeStatus"
      :selected="selectedSubject"
      @change-status="changeStatusSubject"
    >
      <template v-slot:title>
        ¿Deseas cambiar el estado de esta asignatura?
      </template>
      <template v-slot:subject>
        la asignatura
        <span class="font-weight-bolder">{{ selectedSubject.name }}</span>
      </template>
    </ChangeStatusBaseModal>

    <ManageAcademicLoadModal
      ref="mdlAcademicLoad"
      :subject="selectedSubject"
    ></ManageAcademicLoadModal>

    <AssignSubjectTeachersModal
      ref="mdlSubjectTeachers"
      :subject="selectedSubject"
    >
    </AssignSubjectTeachersModal>

    <SubjectAcademicGroupsListModal
      ref="mdlSubjectAcademicGroups"
      :subject="selectedSubject"
    ></SubjectAcademicGroupsListModal>
    <!-- end::Modals -->
  </div>
</template>

<script>
import subjectRepository from "@/repositories/subjectRepository";
import subjectEvaluationScaleRepository from "@/repositories/subjectEvaluationScaleRepository";
import gradeRepository from "@/repositories/gradeRepository";
import specialityRepository from "@/repositories/specialityRepository";
import CreateSubjectModal from "@/components/modals/subjects/CreateSubjectModal.vue";
import UpdateSubjectModal from "@/components/modals/subjects/UpdateSubjectModal.vue";
import DeleteBaseModal from "@/components/modals/DeleteBaseModal.vue";
import ChangeStatusBaseModal from "@/components/modals/ChangeStatusBaseModal.vue";
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import ManageAcademicLoadModal from "@/components/modals/subjects/ManageAcademicLoadModal.vue";
import AssignSubjectTeachersModal from "@/components/modals/subjects/AssignSubjectTeachersModal.vue";
import SubjectAcademicGroupsListModal from "@/components/modals/subjects/SubjectAcademicGroupsListModal.vue";

export default {
  name: "Subjects",
  title: "Asignaturas | GE ITR",
  components: {
    CreateSubjectModal,
    UpdateSubjectModal,
    DeleteBaseModal,
    ChangeStatusBaseModal,
    ManageAcademicLoadModal,
    AssignSubjectTeachersModal,
    SubjectAcademicGroupsListModal,
  },
  data() {
    return {
      subjects: {
        headers: [
          { text: "Asignatura", value: "name" },
          { text: "Código de la asignatura", value: "code" },
          {
            text: "Tipo de asignatura",
            value: "subject_evaluation_scale.subject_type",
          },
          {
            text: "Metodología de evaluación",
            value: "subject_evaluation_scale.subject_evaluation_methodology",
          },
          { text: "Grado", value: "grade" },
          { text: "Especialidad", value: "speciality" },
          { text: "Acciones", value: "actions", sortable: false },
        ],
        data: [],
        search: "",
        isLoading: false,
        filters: {},
      },
      selectedSubject: {},
      subjectEvaluationScales: { data: [], isLoading: false },
      grades: { data: [], isLoading: false },
      specialities: { data: [], isLoading: false },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Asignaturas", route: "subjects" },
    ]);
    this.loadSubjects();
    this.getSubjectEvaluationScales();
    this.getGrades();
    this.getSpecialities();
    this.$store.dispatch(GET_CURRENT_PAGE_ACTIONS, "Asignaturas");
  },
  methods: {
    selectSubject(obj) {
      // Creating a non reactive copie of data
      this.selectedSubject = { ...obj };
    },
    unselectSubject() {
      this.selectedSubject = {};
    },
    showMdlCreate() {
      this.$refs.mdlCreate.toggleModal();
    },
    showMdlUpdate() {
      this.$refs.mdlUpdate.toggleModal();
    },
    showMdlDelete() {
      this.$refs.mdlDelete.toggleModal();
    },
    showMdlChangeStatus() {
      this.$refs.mdlChangeStatus.toggleModal();
    },
    showMdlAcademicLoad() {
      this.$refs.mdlAcademicLoad.toggleModal();
    },
    showMdlSubjectTeachers() {
      this.$refs.mdlSubjectTeachers.toggleModal();
    },
    showMdlSubjectAcademicGroups() {
      this.$refs.mdlSubjectAcademicGroups.toggleModal();
    },
    loadSubjects() {
      this.subjects.isLoading = true;
      subjectRepository
        .getAllSubjects()
        .then(({ data }) => {
          this.subjects.data = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.subjects.isLoading = false;
        });
    },
    deleteSubject() {
      subjectRepository
        .deleteSubject(this.selectedSubject.id)
        .then(({ data }) => {
          // Showing alert with response data
          this.sweetAlertResponse(data);
          // Retrieving data if operation has been completed
          if (data.status) {
            this.loadSubjects();
          }
        })
        .catch((error) => {
          // Sweetalert that indicates the operation was unsuccesfull
          this.fireToast({
            icon: "error",
            title: `No es posible eliminar el registro ${this.selectedSubject.name} en estos momentos 🙁`,
          });
        });
    },
    changeStatusSubject() {
      subjectRepository
        .changeStatusSubject(this.selectedSubject.id)
        .then(({ data }) => {
          // Showing alert with response data
          this.sweetAlertResponse(data);
          // Retrieving data if operation has been completed
          if (data.status) {
            this.loadSubjects();
          }
        })
        .catch((error) => {
          // Sweetalert that indicates the operation was unsuccesfull
          this.fireToast({
            icon: "error",
            title: `No es posible cambiar el estado del registro seleccionado en estos momentos 🙁`,
          });
        });
    },
    getSubjectEvaluationScales() {
      this.subjectEvaluationScales.isLoading = true;
      subjectEvaluationScaleRepository
        .getAllSubjectEvaluationScales()
        .then(({ data }) => {
          this.subjectEvaluationScales.data = data;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.subjectEvaluationScales.isLoading = false;
        });
    },
    getGrades() {
      this.grades.isLoading = true;
      gradeRepository
        .getAllGrades()
        .then(({ data }) => {
          this.grades.data = data;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.grades.isLoading = false;
        });
    },
    getSpecialities() {
      this.specialities.isLoading = true;
      specialityRepository
        .getAllSpecialities()
        .then(({ data }) => {
          this.specialities.data = data;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.specialities.isLoading = false;
        });
    },
  },
  computed: {
    ...mapGetters(["currentPageActions"]),
    filteredData() {
      return this.filterData(this.subjects.filters, this.subjects.data);
    },
  },
  beforeDestroy() {
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
  },
};
</script>
