import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllStageTypes = () => {
  return ApiService.get("stage_types");
};

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllStageTypesWithStages = () => {
  return ApiService.get("stage_types/stages");
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getStageType = (id) => {
  return ApiService.get("stage_types", id);
};

/**
 * POST request to create a new stage_type
 * @param payload
 * @returns {*}
 */
export const createStageType = (payload) => {
  return ApiService.post("stage_types", payload);
};

/**
 * PUT request to update a specific stage_type
 * @param id
 * @param payload
 * @returns {*}
 */
export const updateStageType = (id, payload) => {
  return ApiService.update("stage_types", id, payload);
};

/**
 * DELETE request to delete the specified stage_type
 * @param id
 * @returns {*}
 */

export const deleteStageType = (id) => {
  return ApiService.delete(`stage_types/${id}`);
};

/**
 * GET request to change status of the specified stage_type
 * @param id
 * @returns {*}
 */
export const changeStatusStageType = (id) => {
  return ApiService.get("stage_types/status", id);
};

export default {
  getAllStageTypes,
  getAllStageTypesWithStages,
  getStageType,
  createStageType,
  updateStageType,
  deleteStageType,
  changeStatusStageType,
};
