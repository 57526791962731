import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllSpecialities = () => {
  return ApiService.get("specialities");
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getSpeciality = (id) => {
  return ApiService.get("specialities", id);
};

/**
 * POST request to create a new speciality
 * @param payload
 * @returns {*}
 */
export const createSpeciality = (payload) => {
  return ApiService.post("specialities", payload);
};

/**
 * PUT request to update a specific speciality
 * @param id
 * @param payload
 * @returns {*}
 */
export const updateSpeciality = (id, payload) => {
  return ApiService.update("specialities", id, payload);
};

/**
 * DELETE request to delete the specified speciality
 * @param id
 * @returns {*}
 */
export const deleteSpeciality = (id) => {
  return ApiService.delete(`specialities/${id}`);
};

/**
 * GET request to change status of the specified speciality
 * @param id
 * @returns {*}
 */
export const changeStatusSpeciality = (id) => {
  return ApiService.get("specialities/status", id);
};

export default {
  getAllSpecialities,
  getSpeciality,
  createSpeciality,
  updateSpeciality,
  deleteSpeciality,
  changeStatusSpeciality,
};
