<template>
  <v-dialog
    v-model="dialog"
    scrollable
    transition="dialog-bottom-transition"
    persistent
    max-width="850"
  >
    <v-form>
      <v-card>
        <v-toolbar dark color="deep-purple" class="elevation-0 px-3">
          <span class="headline">Asignar etapas a la asignatura</span>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <!-- begin::Subject info -->
            <v-row v-if="Object.keys(subject).length > 0">
              <v-col>
                <div class="black--text mb-1 flex-column">
                  <div class="text-body-2">Asignatura:</div>
                  <span class="text-h6 font-weight-medium">{{
                    subject.name
                  }}</span>
                </div>
              </v-col>
              <v-col>
                <div class="black--text mb-1 flex-column">
                  <div class="text-body-2">Tipo de asignatura:</div>
                  <span class="text-h6 font-weight-medium">{{
                    `${subject.subject_evaluation_scale.subject_type}, ${subject.subject_evaluation_scale.subject_evaluation_methodology}`
                  }}</span>
                </div>
              </v-col>
              <v-col>
                <div class="black--text mb-1 flex-column">
                  <div class="text-body-2">Grado:</div>
                  <span class="text-h6 font-weight-medium">{{
                    subject.grade ? subject.grade : ""
                  }}</span>
                </div>
              </v-col>
              <v-col>
                <div class="black--text mb-1 flex-column">
                  <div class="text-body-2">Especialidad:</div>
                  <span class="text-h6 font-weight-medium">{{
                    subject.speciality ? subject.speciality : ""
                  }}</span>
                </div>
              </v-col>
            </v-row>
            <!-- end::Subject info -->
          </v-container>
          <v-divider class="mx-4 my-0"></v-divider>
          <v-container>
            <v-row>
              <v-col>
                <span class="text-body-1"
                  >Selecciona las etapas en las que se impartirá la
                  asignatura</span
                >
              </v-col>
            </v-row>
            <!-- begin::ALerta informativa -->
            <v-alert
              v-if="stageTypes.length == 0"
              dense
              text
              type="info"
              class="mt-2"
            >
              No hay ningún tipo de etapa registrado, asegurate de registrarlas
              en el apartado de tipo de etapas y posteriormente agregar etapas
              por cada tipo en el apartado de etapas.
            </v-alert>
            <!-- end::ALerta informativa -->
            <!-- begin::Stage Types List with their Stages -->
            <v-row v-for="stageType in stageTypes" :key="stageType.id" v-else>
              <v-col>
                <h2 class="text-subtitle-1 font-weight-bold mb-1">
                  {{ stageType.name }}
                </h2>
                <!-- begin::ALerta informativa -->
                <v-alert
                  v-if="stageType.stages.length == 0"
                  dense
                  text
                  type="info"
                >
                  No hay ninguna etapa registrada para este tipo, asegurate de
                  registrarlas en el apartado de etapas.
                </v-alert>
                <!-- end::ALerta informativa -->
                <!-- begin::Options -->
                <v-chip-group
                  v-model="stages"
                  column
                  multiple
                  active-class="deep-purple--text"
                  v-else
                >
                  <v-chip
                    filter
                    outlined
                    v-for="stage in stageType.stages"
                    :key="stage.id"
                    :value="stage.id"
                  >
                    <span class="text--primary font-weight-medium">
                      {{ stage.name }}
                    </span>
                    {{ `, desde ${stage.start_date} hasta ${stage.end_date}` }}
                  </v-chip>
                </v-chip-group>
                <!-- end::Options -->
              </v-col>
            </v-row>
            <!-- end::Stage Types List with their Stages -->
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end pt-0">
          <v-btn text outlined class="mr-1" @click="closeModal">Cerrar</v-btn>
          <v-btn
            color="deep-purple"
            dark
            depressed
            :loading="isLoading"
            @click="save"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import stageTypeRepository from "@/repositories/stageTypeRepository";
import academicLoadRepository from "@/repositories/academicLoadRepository";

export default {
  name: "ManageAcademicLoadModal",
  data() {
    return {
      isLoading: false,
      dialog: false,
      stageTypes: [],
      stages: [],
      env: process.env.APP_ENV,
    };
  },
  props: {
    subject: {
      type: Object,
    },
  },
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },

    closeModal() {
      this.toggleModal();
      this.stageTypes = [];
      this.stages = [];
    },

    save() {
      this.isLoading = true;
      const payload = {
        subject_id: this.subject.id,
        stages: this.stages,
      };
      academicLoadRepository
        .updateAcademicLoad(this.subject.id, payload)
        .then(({ data }) => {
          // Showing alert with response data
          this.sweetAlertResponse(data);
          // Retrieving data and hidding modal if operation has been completed
          if (data.status) {
            this.$emit("reload-data");
            this.closeModal();
            this.stages = [];
          }
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible guardar los registros 🙁",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getSubjectTypes() {
      stageTypeRepository
        .getAllStageTypesWithStages()
        .then(({ data }) => {
          this.stageTypes = data;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        });
    },
    getAcademicLoadOfSubject(id) {
      academicLoadRepository
        .getAcademicLoadsBySubject(id)
        .then(({ data }) => {
          // Guardando en el array de etapas el id de las que ya estan relacionados con la asignatura para mostrarla seleccionada
          data.forEach((item) => {
            this.stages.push(item.stage_id);
          });
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        });
    },
  },
  watch: {
    dialog(newValue, oldValue) {
      // If modal was open, make request for fill sections and stageTypes
      if (newValue) {
        this.getSubjectTypes();
        this.getAcademicLoadOfSubject(this.subject.id);
      }
    },
    stages(newValue, oldValue) {
      // Validación para que solo se puedan seleccionar etapas de un mismo tipo
      if (newValue.length > oldValue.length && oldValue.length > 0) {
        // Obteniendo el tipo de etapa de la última etapa que habia sido seleccionada
        const typeOfPreviousSelectedStages = this.stageTypes.findIndex((item) =>
          item.stages.some((stage) => stage.id == oldValue[oldValue.length - 1])
        );
        // Obteniendo el tipo de etapa de la etapa que acaba de ser seleccionada
        const typeOfSelectedStage = this.stageTypes.findIndex((item) =>
          item.stages.some((stage) => stage.id == newValue[newValue.length - 1])
        );
        // Si son diferentes los tipos no deja seleccionar el nuevo
        if (typeOfPreviousSelectedStages != typeOfSelectedStage) {
          this.stages = [...oldValue];
          this.fireToast({
            icon: "error",
            title: "No es posible seleccionar etapas de diferentes tipos",
          });
        }
      }
    },
  },
};
</script>
