<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    max-width="550"
  >
    <v-form>
      <v-card>
        <v-toolbar dark color="primary" class="elevation-0 px-3">
          <span class="headline">Agregar una nueva asignatura</span>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12" sm="12" class="pb-0">
                <form-group
                  name="nombre de la asignatura"
                  :validator="$v.subject.name"
                >
                  <v-text-field
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    label="Asignatura"
                    placeholder="Ingresa el nombre de la asignatura"
                    required
                    outlined
                    v-model="subject.name"
                  />
                </form-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" sm="6" class="pb-0">
                <form-group
                  name="código de la asignatura"
                  :validator="$v.subject.code"
                >
                  <v-text-field
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    label="Código de asignatura"
                    placeholder="Ingresa el código de la asignatura"
                    required
                    outlined
                    v-model="subject.code"
                  />
                </form-group>
              </v-col>
              <v-col col="12" sm="6" class="pb-0">
                <form-group
                  name="tipo de asignatura"
                  :validator="$v.subject.subject_evaluation_scale_id"
                >
                  <v-select
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    label="Tipo de asignatura"
                    placeholder="Selecciona un tipo de asignatura"
                    :items="subjectEvaluationScales"
                    item-text="name"
                    item-value="id"
                    required
                    outlined
                    v-model="subject.subject_evaluation_scale_id"
                  >
                    <template v-slot:selection="{ item }">
                      <span>
                        {{
                          `${item.subject_type}, ${item.subject_evaluation_methodology}`
                        }}
                      </span>
                    </template>
                    <template v-slot:item="data">
                      <span>
                        {{
                          `${data.item.subject_type}, ${data.item.subject_evaluation_methodology}`
                        }}
                      </span>
                    </template>
                  </v-select>
                </form-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col col="12" sm="6" class="pb-0">
                <form-group name="grado" :validator="$v.subject.grade_id">
                  <v-select
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    label="Grado"
                    placeholder="Selecciona un grado"
                    :items="grades"
                    item-text="name"
                    item-value="id"
                    required
                    outlined
                    v-model="subject.grade_id"
                  ></v-select>
                </form-group>
              </v-col>
              <v-col col="12" sm="6" class="pb-0">
                <form-group
                  name="especialidad"
                  :validator="$v.subject.speciality_id"
                >
                  <v-select
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    label="Especialidad"
                    placeholder="Selecciona una especialidad"
                    :items="specialities"
                    item-text="name"
                    item-value="id"
                    outlined
                    required
                    v-model="subject.speciality_id"
                  ></v-select>
                </form-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                <v-switch
                  v-model="subject.is_required_mined"
                  flat
                  :label="
                    `¿Es asignatura obligatoria? ${
                      subject.is_required_mined ? 'Si' : 'No'
                    }`
                  "
                  persistent-hint
                  hint="Si es asignatura obligatoria significa que es requerida por el MINED, si no, solo es requerida por la institución."
                  class="mt-0"
                ></v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end pt-0">
          <v-btn text outlined class="mr-1" @click="closeModal">Cerrar</v-btn>
          <v-btn color="primary" depressed :loading="isLoading" @click="submit"
            >Agregar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import subjectRepository from "@/repositories/subjectRepository";

export default {
  name: "CreateSubjectModal",

  data() {
    return {
      isLoading: false,
      dialog: false,
      subject: {
        is_required_mined: true,
      },
    };
  },
  props: {
    subjectEvaluationScales: {
      type: Array,
    },
    grades: {
      type: Array,
    },
    specialities: {
      type: Array,
    },
  },
  methods: {
    create() {
      this.isLoading = true;
      subjectRepository
        .createSubject(this.subject)
        .then(({ data }) => {
          // Showing alert with response data
          this.sweetAlertResponse(data);
          // Retrieving data and hidding modal if operation has been completed
          if (data.status) {
            this.$emit("reload-data");
            this.closeModal();
          }
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible guardar el registro 🙁",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.fireToast({
          icon: "error",
          title: "Asegurate de llenar el formulario correctamente",
        });
        return;
      }
      this.create();
    },

    toggleModal() {
      this.dialog = !this.dialog;
    },

    closeModal() {
      this.toggleModal();
      this.$v.$reset();
      this.subject = {
        is_required_mined: true,
      };
    },
  },
  validations: {
    subject: {
      name: {
        required,
        maxLength: maxLength(255),
      },
      code: {
        required,
      },
      subject_evaluation_scale_id: {
        required,
      },
      grade_id: {
        required,
      },
      speciality_id: {
        required,
      },
    },
  },
};
</script>
