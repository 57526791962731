<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      transition="dialog-bottom-transition"
      max-width="400"
    >
      <v-form>
        <v-card>
          <v-card-title class="text-h6">
            <div class="d-flex flex-row">
              <v-icon medium color="cyan" left>mdi-alert</v-icon>
              <slot name="title"></slot>
            </div>
          </v-card-title>
          <v-card-text class="text-body-1 black--text text-justify">
            Atención: si confirma
            <slot name="subject"></slot>, se
            {{
              selected.status == 1
                ? "desactivará y no será visible"
                : "activará y volverá a ser visible"
            }}.
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end pt-0">
            <v-btn text outlined class="mr-1" @click="cancel">Cancelar</v-btn>
            <v-btn
              color="cyan"
              class="white--text"
              depressed
              :loading="isLoading"
              @click="confirm"
              >Sí, {{ selected.status == 1 ? "desactivar" : "activar" }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>  
export default {
  name: "ChangeStatusBaseModal",
  data() {
    return {
      isLoading: false,
      dialog: false,
    };
  },
  props: {
    selected: {
      type: Object,
    },
  },
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },

    cancel() {
      this.fireToast({
        icon: "info",
        title: "El registro selecionado no ha sido modificado.",
      });
      this.toggleModal();
    },

    confirm() {
      this.isLoading = true;
      this.$emit("change-status");
      this.toggleModal();
      this.isLoading = false;
    },
  },
};
</script>
